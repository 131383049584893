/*********************************

 File:       Main.js
 Function:   Root holder, background
 Copyright:  Bertco LLC
 Date:       2020-03-05
 Author:     mkahn

 **********************************/

import React from 'react';

const Main = props => {
    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: '#303030'}}>
            <div style={{height: '5vh'}}/>
            <div className="container p-3 mx-auto hero-bkg w-100"
                 style={{ borderRadius: '10px 10px 10px 10px'}}>
            {props.children}
            </div>
        </div>
    );
};

export default Main;
