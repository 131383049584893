import React from 'react';
import './assets/styles/winexstrap.css';
import Main from './components/Main';
import {Row, Col, Container} from 'reactstrap';
import wxlogo from './assets/images/winex-logo-300.png';
import 'react-circular-progressbar/dist/styles.css';
import Countdown from "./components/Countdown";

function App() {
    return (
        <Main>
            <Container>
                <Row>
                    <Col style={{background: 'rgba(0,0,0,0.75', minHeight: '500px'}} sm={12}>
                        <img src={wxlogo} className="d-block mx-auto m-5"/>
                        <p className="text-center" style={{color: '#b0b0b0'}}>coming soon as COVID-19 is controlled...</p>
                        <Countdown/>
                        {/*<a href="sibly://this/link/99/more/27">Test</a>*/}
                    </Col>
                </Row>
            </Container>
        </Main>
    );
}

export default App;
