/*********************************

 File:       Countdown.js
 Function:   Circle timer
 Copyright:  Bertco LLC
 Date:       2020-03-05
 Author:     mkahn

 **********************************/

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {CircularProgressbar} from 'react-circular-progressbar';
import {Container, Row, Col} from 'reactstrap';


const LAUNCH_DAY = moment('August 15, 2021 8:00');

const dmsDelta = () => {
    let delta = (new Date(LAUNCH_DAY).getTime() - new Date().getTime()) / 1000;
    const days = Math.floor(delta / (60 * 60 * 24));
    delta -= days * (60 * 60 * 24);
    const hours = Math.floor(delta / (60 * 60));
    delta -= hours * (60 * 60);
    const minutes = Math.floor(delta / (60));
    delta -= minutes * 60;
    return {days, hours, minutes, seconds: Math.floor(delta)}
};

const SmallProgBar = props => {


    return (
        <div style={{width: '100px', height: '100px', marginTop: '50px'}}>
            <CircularProgressbar {...props}/>
        </div>
    )
};

const Countdown = props => {

    const [dms, setDms] = useState(dmsDelta());

    useEffect(() => {
        const zed = setInterval(() => {
            setDms(dmsDelta());
            console.log('tick')
        }, 1000);
        return () => clearInterval(zed);
    }, []);

    return (
        <Container className="p-5 mx-auto">
            <Row>
                <Col>
                    <SmallProgBar value={dms.days} minValue={0} maxValue={365} text={`${dms.days}D`}/>
                </Col>
                <Col>
                    <SmallProgBar value={dms.hours} minValue={0} maxValue={59} text={`${dms.hours}H`}/>
                </Col>
                <Col>
                    <SmallProgBar value={dms.minutes} minValue={0} maxValue={59} text={`${dms.minutes}M`}/>
                </Col>
                <Col>
                    <SmallProgBar value={dms.seconds} minValue={0} maxValue={59} text={`${dms.seconds}S`}/>
                </Col>
            </Row>
        </Container>
    );
};

Countdown.propTypes = {};

export default Countdown;
